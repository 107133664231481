'use strict';

// Kjører JS etter ninjaforms har rendret et form
// obs! dette skriptet må inkluderes etter nf-front-end
// kode i applyFormFixes kjører etter ninjaform er rendret


jQuery(function () {
	if (typeof Marionette != "undefined") {
		// kun hvis nf brukes på denne siden
		var nfFormFixer = Marionette.Object.extend({
			initialize: function initialize() {
				this.listenTo(Backbone.Radio.channel('form'), 'render:view', this.applyFormFixes);
			},
			applyFormFixes: function applyFormFixes() {
				jQuery(".email-source option").each(function (i) {
					var email = jQuery(this).val();
					email = email + "-*-" + i;
					jQuery(this).val(email);
				});
				jQuery('.email-source').on('change', function () {
					var email = jQuery(this).find("option:selected").val();
					var parts = email.split("-*-");
					if (parts.length > 1) {
						email = parts[0];
					} else {
						email = "bedriftfaktura@folloren.no";
					}
					var innsender = jQuery(this).find("option:selected").text();
					jQuery('.email-target').val(email);
					jQuery('.innsender-target').val(innsender);
				});
				jQuery('.nf-field-container.field-is-readonly input').attr('disabled', 'disabled');
				jQuery('.nf-field-container.field-is-readonly input').attr('readonly', 'readonly');
				jQuery('.nf-field-container.field-is-hidden').hide();
			}

		});
		new nfFormFixer();

		/*
		// Validate Total Amount field
		var nfFormPaymentFieldsValidator = Marionette.Object.extend({
			initialize: function () {

				// On the Form Submission's field validaiton...
				var submitChannel = Backbone.Radio.channel('submit');
				this.listenTo(submitChannel, 'validate:field', this.validateRequired);

				// on the Field's model value change...
				var fieldsChannel = Backbone.Radio.channel('fields');
				this.listenTo(fieldsChannel, 'change:modelValue', this.validateRequired);
			},

			validateRequired: function (model) {

				// Total field's type.
				if ('textbox' != model.get('type')) return;

				//check if total amount field
				if ('total_amount' != model.get('key')) return;

				var msg1 = 'Velg produkter du vil oppdatere totalt.';
				var msg2 = 'Totalbeløpet er mindre enn 1 kr.';

				// Check if Model has a value
				if (model.get('value')) {
					if (model.get('value') < 1) {
						// Add Error to Model - Amount less than 1
						Backbone.Radio.channel('fields').request('add:error', model.get('id'), 'total-amount-field-error', msg2);
					}
					else {
						// Remove Error from Model
						Backbone.Radio.channel('fields').request('remove:error', model.get('id'), 'total-amount-field-error');
					}
				} else {
					// Add Error to Model - Amount empty value
					Backbone.Radio.channel('fields').request('add:error', model.get('id'), 'total-amount-field-error', msg1);
				}
			}
		});
		new nfFormPaymentFieldsValidator();
		*/

		var nfFormSubmitActions = Marionette.Object.extend({
			initialize: function () {
				this.listenTo(Backbone.Radio.channel('forms'), 'submit:response', this.actionSubmit);
			},

			actionSubmit: function (response) {
				//console.log(response)
				if ('undefined' != typeof response.redirect) {
					window.location.href = response.redirect;
				}
			},
		});
		new nfFormSubmitActions();
	}

	// Update total amount based on product selection
	jQuery(document).on('nfFormReady', function () {
		jQuery(".payment-mode-wrapper input[type=radio][value=vipps]").attr('disabled',true);
		jQuery(".payment-mode-wrapper input[type=radio][value=vipps]").parent().hide();
		jQuery('.nf-field-container.total-amount-wrapper').hide(); // Hide through css later. Never display the field.
	
		var $mainProductEl = jQuery('.nf-field-container.main-product-wrapper input[type=radio]');
		var $subProductEl = jQuery('.nf-field-container.sub-product-wrapper input[type=checkbox]');
		var $totalAmountEl = jQuery('.nf-field-container.total-amount-wrapper input.total-amount');
		var $totalAmountWrapper = jQuery('.nf-field-container.total-amount-wrapper');

		if ($mainProductEl.length) {
			$mainProductEl.on('change', function () {
				var validaitonCond = true, newVal = jQuery(this).val() ? parseFloat(jQuery(this).val()) : 0;

				if($subProductEl.length){
					$subProductEl.each(function(){
						if(jQuery(this).prop('checked')){
							newVal += parseFloat(jQuery(this).val());
						}
					});
					if(!$subProductEl.is(':checked')) validaitonCond = false;
				}

				$totalAmountEl.val(newVal).trigger('change');

				// // setTimeout(function(){
					if(!$totalAmountWrapper.hasClass('is-display-verified') && validaitonCond && $mainProductEl.is(':checked')){
						display_total_amount_field($totalAmountEl.val());
					}
				// // }, 500);
			});
		}

		if ($subProductEl.length) {
			$subProductEl.on('change', function () {
				var newVal = 0, validaitonCond = true;
				$subProductEl.each(function(){
					if(jQuery(this).prop('checked')){
						newVal += parseFloat(jQuery(this).val());
					}
				});

				if ($mainProductEl.length) {
					newVal += $mainProductEl.filter(":checked").val() ? parseFloat($mainProductEl.filter(":checked").val()) : 0;
					if(!$mainProductEl.is(':checked')) validaitonCond = false;
				}

				$totalAmountEl.val(newVal).trigger('change');

				// // setTimeout(function(){
					if(!$totalAmountWrapper.hasClass('is-display-verified') && $subProductEl.is(':checked') && validaitonCond){
						display_total_amount_field($totalAmountEl.val());
					}
				// // }, 500);

			});
		}

		if($totalAmountEl.length){
			$totalAmountEl.attr('readonly', true);
			var newVal = 0;

			// Set default amount based on product selections
			newVal += ($mainProductEl.length > 0 && $mainProductEl.val()) ? parseFloat($mainProductEl.val()) : 0;
			if($subProductEl.length){
				$subProductEl.each(function(){
					if(jQuery(this).prop('checked')){
						newVal += parseFloat(jQuery(this).val());
					}
				});
			}
			$totalAmountEl.val(newVal);

			$totalAmountEl.on('change', function () {
				// if(!jQuery(this).hasClass('is-display-verified') && $subProductEl.is(':checked') && $mainProductEl.is(':checked')){
				// 	display_total_amount_field(jQuery(this).val());
				// }
				if(jQuery('.nf-field-container.total-amount-wrapper').closest('.nf-cell').find('span.total-amount-text'))
					jQuery('.nf-field-container.total-amount-wrapper').closest('.nf-cell').find('span.total-amount-text').html(parseFloat(jQuery(this).val()).toLocaleString("no-NO"));
			});
		}
		
		if ( jQuery( ".payment-mode-wrapper" ).length ) {
			var formID = jQuery("input[type=hidden][value*=formid]").val();
			var data = {
				'action': 'hide_radio_option',
				'form_data' : formID
			};
			jQuery.ajax({
				url: myAjax.ajaxurl,
				type: 'POST',
				data: data,
				success: function(rdata) {
					if(rdata['enable_payment'])	
					{
						jQuery(".payment-mode-wrapper input[type=radio][value=vipps]").attr('disabled',false);
						jQuery(".payment-mode-wrapper input[type=radio][value=vipps]").parent().show();
					}
				}
			});
		}
		
	});
});

function display_total_amount_field(amount){
	var formID = jQuery(".nf-field-container.hidden-container input[type=hidden][value*=formid]").val().split('-')[1];
	var data = {
		'action': 'get_form_setting_value',
		'form_id' : formID,
		'settings' : 'display_total_field'
	};
	jQuery.ajax({
		url: myAjax.ajaxurl,
		type: 'POST',
		data: data,
		success: function(data) {
			if(data['display_total_field'] == 1)	{
				if(jQuery('.nf-field-container.total-amount-wrapper').closest('.nf-cell').find('span.total-amount-text').length)
					jQuery('.nf-field-container.total-amount-wrapper').closest('.nf-cell').find('span.total-amount-text').html(parseFloat(amount).toLocaleString("no-NO"));
				else
					jQuery('.nf-field-container.total-amount-wrapper').closest('.nf-cell').prepend('<div style="margin-bottom: 25px;">Total: <span class="total-amount-text">'+amount.toLocaleString("no-NO")+'</span> NOK </div>');
			}
			jQuery('.nf-field-container.total-amount-wrapper').addClass('is-display-verified');
		}
	});
}
